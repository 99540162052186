
import { RoleDropdownItem } from '@bd/admin/types'
import { defineComponent, PropType } from 'vue'
import RoleDropdownListItem from './RoleDropdownListItem.vue'

export default defineComponent({
  name: 'RoleDropdownList',
  emits: ['dropdown-click'],
  components: { RoleDropdownListItem },
  props: {
    items: {
      type: Array as PropType<RoleDropdownItem[]>,
      required: true,
    },
    selectedRole: {
      type: Object as PropType<RoleDropdownItem>,
      required: false,
    },
  },
  setup(_, { emit }) {
    const onItemSelected = (item: RoleDropdownItem) => {
      emit('dropdown-click', item.value)
    }

    return {
      onItemSelected,
    }
  },
})
