
import { UserRole } from '@bd/api'
import { DropdownItem } from '@bd/components'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'RoleDropdownListItem',
  props: {
    item: {
      type: Object as PropType<DropdownItem<UserRole>>,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
})
