<template>
  <div
    class="role-dropdown-list-item d-flex align-items-center"
    :class="{ active: isActive }"
  >
    <p>{{ item.label }}</p>
  </div>
</template>

<script lang="ts">
import { UserRole } from '@bd/api'
import { DropdownItem } from '@bd/components'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'RoleDropdownListItem',
  props: {
    item: {
      type: Object as PropType<DropdownItem<UserRole>>,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.role-dropdown-list-item {
  height: 55px;
  &:not(:last-of-type) {
    border-bottom: 1px solid $athens-gray;
  }
  &:hover {
    cursor: pointer;
    color: $primary-color;
  }
}

.active {
  color: $primary-color;
}
</style>
