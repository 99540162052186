<template>
  <DropdownButton
    contentClass="button-dropdown-content"
    v-model:isOpen="isDropdownOpen"
  >
    <Button class="p-button-sm p-button-secondary p-button-rounded">
      <template v-if="selectedRole">
        <span>{{ selectedRole.label }}</span>
        <i @click.stop="clearRole" class="pi pi-times ml-2 c-primary" />
      </template>
      <span v-else>{{ t('employees.allEmployees') }}</span>
      <i class="pi pi-chevron-down ml-2 c-primary"></i>
    </Button>

    <template #content>
      <RoleDropdownList
        :items="roles"
        @dropdown-click="onDropdownClick"
        :selectedRole="selectedRole"
      />
    </template>
  </DropdownButton>
</template>

<script lang="ts">
import RoleDropdownList from '@bd/admin/components/Users/RoleDropdown/RoleDropdownList.vue'
import { RoleDropdownItem } from '@bd/admin/types'
import { UserRole } from '@bd/api'
import { DropdownButton } from '@bd/components'
import { defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'RoleDropdown',
  components: {
    DropdownButton,
    RoleDropdownList,
  },
  props: {
    roles: {
      type: Array as PropType<RoleDropdownItem[]>,
      required: true,
    },
    selectedRole: {
      type: Object as PropType<RoleDropdownItem>,
      required: false,
    },
  },
  emits: ['update:selectedRole', 'clear'],
  setup(_, { emit }) {
    const { t } = useI18n()
    const isDropdownOpen = ref(false)

    const onDropdownClick = async (role: UserRole) => {
      emit('update:selectedRole', role)
      isDropdownOpen.value = false
    }

    const clearRole = () => {
      emit('clear')
    }

    return {
      t,
      isDropdownOpen,
      onDropdownClick,
      clearRole,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.button-dropdown-content.dropdown-content) {
  top: 50px;
  padding: 25px;
  border-radius: 7px;
  box-shadow: 0 25px 190px 0 $light-shadow;
  background: #ffffff;
  z-index: 2;
  @media (min-width: 768px) {
    min-width: 400px;
  }
  @media (max-width: 768px) {
    width: 200px;
  }
}
</style>
