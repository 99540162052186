<template>
  <div class="container-fluid clients" @scroll="onScroll">
    <div class="row">
      <div class="col-12">
        <AppContentLayout tools>
          <template #title>
            <span>{{ t('navigation.clients') }}</span>
          </template>
          <template #tools-filters>
            <div class="d-flex filters">
              <RoleDropdown
                class="mr-3 client-roles-dropdown"
                :roles="clientRoles"
                :selectedRole="selectedClientRole"
                @update:selectedRole="onSelectedClientRoleChange"
                @clear="onSelectedClientRoleClear"
              />
              <AgentDropdownComponent
                class="mr-3"
                :agents="agents"
                :selectedAgent="selectedAgent"
                :unselectedLabel="t('employees.allAgents')"
                @select="selectedAgentHandle"
              />
              <div class="mr-3 clients-search">
                <SearchInput
                  class="bg-white"
                  :modelValue="clientsSearchPhrase"
                  :placeholder="t('clients.search')"
                  @search="searchClients"
                  @update:modelValue="updateSearchPhrase"
                />
              </div>
            </div>
          </template>
          <template #tools-actions>
            <Button
              class="p-button-sm p-button-primary p-button-rounded"
              :label="t('clients.add')"
              @click="addClientClick"
            />
          </template>
          <Loader :is-loading="isLoading" :margin-left="navigationWidth" />
          <EmptyState
            v-if="!clients.length && !isLoading"
            :title="t('clients.emptyState.title')"
            :description="t('clients.emptyState.description')"
          />
          <div v-if="clients.length" class="card-grid">
            <div v-for="client in clients" :key="client.email">
              <UserCard
                :user="client"
                :displayOptions="['details', 'chip', 'id']"
                :isIdReversed="true"
                @click="onClientClickHandle(client.id)"
              />
            </div>
          </div>
        </AppContentLayout>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { default as common } from '@/config/common.json'
import AgentDropdownComponent from '@bd/admin/components/AgentDropdown/AgentDropdown.vue'
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'
import RoleDropdown from '@bd/admin/components/Users/RoleDropdown/RoleDropdown.vue'
import { useAppStore } from '@bd/admin/store'
import { AgentContent, RoleDropdownItem } from '@bd/admin/types'
import { useSelectedAgent } from '@bd/admin/use/useSelectedAgent'
import { ClientParams, UserRole } from '@bd/api'
import {
  Loader,
  useInfiniteScroll,
  useRootData,
  SearchInput,
} from '@bd/components'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import UserCard from '@bd/components/User/UserCard/UserCard.vue'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Calendar',
  components: {
    AppContentLayout,
    UserCard,
    EmptyState,
    Loader,
    AgentDropdownComponent,
    SearchInput,
    RoleDropdown,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const router = useRouter()
    const { isLoading } = useRootData()
    const clientsState = store.state.clients
    const { navigationWidth } = common

    const clients = computed(() => clientsState?.clients.content || [])
    const agents = computed(() => store.state.agents?.agents.content || [])

    const storedSearchPhrase = computed(() => clientsState?.filters.name ?? '')
    const clientsSearchPhrase = ref<string>('')
    watch(
      storedSearchPhrase,
      (value) => {
        clientsSearchPhrase.value = value
      },
      { immediate: true },
    )

    const updateSearchPhrase = (searchPhrase: string) => {
      clientsSearchPhrase.value = searchPhrase
    }

    const clientRoles: RoleDropdownItem[] = [
      UserRole.CUSTOMER,
      UserRole.SELLER,
    ].map((role) => ({
      label: t(`enums.userRole.${role}`),
      value: role,
    }))

    const selectedClientRole = computed(() =>
      clientRoles.find(
        (r) => r.value === store.state.clients?.filters.userRole,
      ),
    )

    const setFilterAndRefresh = async (params: ClientParams) => {
      await store.dispatch('clients/setFilterAndRefresh', params)
    }

    const onSelectedClientRoleChange = (userRole: UserRole) => {
      setFilterAndRefresh({ userRole })
    }

    const onSelectedClientRoleClear = () => {
      setFilterAndRefresh({ userRole: undefined })
    }

    const selectedAgent = useSelectedAgent(() =>
      agents.value.find(
        (content: AgentContent) =>
          content.id === store.state.clients?.filters.agentId,
      ),
    )

    const addClientClick = () => {
      store.dispatch('clients/setSelectedClientType', UserRole.SELLER)
      store.commit('clients/RESET_CLIENT_DETAILS')
      router.push({ name: 'ClientSave' })
    }

    const searchClients = (searchPhrase: string) => {
      setFilterAndRefresh({ name: searchPhrase })
    }

    const selectedAgentHandle = async (agentId: number) => {
      setFilterAndRefresh({ agentId })
    }

    const onClientClickHandle = (id: number) => {
      router.push({ name: 'ClientDetails', params: { id } })
    }

    const isLoadedAll = computed(() => {
      const hasNext = clientsState?.clients.hasNext
      return !hasNext
    })

    const pageNumber = computed(() => clientsState?.filters.pageIndex || 0)

    const loadNextPage = async () => {
      const pageIndex = pageNumber.value + 1
      setFilterAndRefresh({ pageIndex })
    }

    const onScroll = useInfiniteScroll(() => {
      if (!isLoadedAll.value) {
        loadNextPage()
      }
    })

    onMounted(async () => {
      await setFilterAndRefresh({ pageIndex: 0 })
      await store.dispatch('agents/agentList')
    })

    return {
      t,
      isLoading,
      navigationWidth,
      agents,
      clients,
      clientRoles,
      selectedClientRole,
      clientsSearchPhrase,
      onSelectedClientRoleChange,
      onSelectedClientRoleClear,
      selectedAgent,
      searchClients,
      selectedAgentHandle,
      onClientClickHandle,
      onScroll,
      addClientClick,
      updateSearchPhrase,
    }
  },
})
</script>

<style lang="scss" scoped>
.clients {
  height: 100vh;
  overflow-y: auto;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 14px;
  row-gap: 40px;
}

:deep(.field-spacing) {
  margin: 0;
}

:deep(.field-spacing .mb-2) {
  margin: 0 !important;
}
:deep(.client-roles-dropdown .dropdown-content) {
  min-width: 160px;
}
.filters {
  align-items: center;
  flex: 1 1 auto;
}
.clients-search {
  flex: 1;
  max-width: 240px;
  min-width: 160px;
}
</style>
