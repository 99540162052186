
import RoleDropdownList from '@bd/admin/components/Users/RoleDropdown/RoleDropdownList.vue'
import { RoleDropdownItem } from '@bd/admin/types'
import { UserRole } from '@bd/api'
import { DropdownButton } from '@bd/components'
import { defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'RoleDropdown',
  components: {
    DropdownButton,
    RoleDropdownList,
  },
  props: {
    roles: {
      type: Array as PropType<RoleDropdownItem[]>,
      required: true,
    },
    selectedRole: {
      type: Object as PropType<RoleDropdownItem>,
      required: false,
    },
  },
  emits: ['update:selectedRole', 'clear'],
  setup(_, { emit }) {
    const { t } = useI18n()
    const isDropdownOpen = ref(false)

    const onDropdownClick = async (role: UserRole) => {
      emit('update:selectedRole', role)
      isDropdownOpen.value = false
    }

    const clearRole = () => {
      emit('clear')
    }

    return {
      t,
      isDropdownOpen,
      onDropdownClick,
      clearRole,
    }
  },
})
